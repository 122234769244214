import { useRef, useMemo, useEffect, useState } from 'react';
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks';
import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Input, Select, Radio, NumberPicker, Switch, TreeSelect } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { InputIcon } from 'src/components/formily';
import { IDeptItem, IPostItem } from 'src/api/types/system';
import { QuerySceneEnum } from 'src/api/types/common';
import { updatePost, createPost, getDeptList } from 'src/api/clients/system';
import { ISelectTree } from 'src/interface';
import { onFormMount } from '@formily/core';
import { operationSchema } from './schema';

interface IProps extends ModalProps {
  data?: IPostItem;
}

export function PostModal(props: IProps) {
  const { open, onOk, onCancel, data, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);

  const isEdit = !!data?.postId;
  const { data: deptTree } = useRequest(async () => {
    const result = await getDeptList({
      offset: 0,
      limit: 10000,
      scene: QuerySceneEnum.TREE,
    });
    const transformData = (data: IDeptItem): ISelectTree => {
      const result: ISelectTree = {
        label: data.deptName ?? '',
        value: Number(data?.deptId),
        children: data.children?.map(transformData),
      };
      return result;
    };
    const tree = result?.map(transformData);
    return tree;
  });

  const onCancelHandle = useMemoizedFn((e) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<IPostItem>();
    const { ...rest } = values ?? {};
    const bodyData = {
      ...rest,
    };
    if (isEdit) {
      bodyData.postId = data?.postId;
    }
    setTrue();
    const request = isEdit ? updatePost : createPost;
    try {
      const res = await request(bodyData as IPostItem);
      if (res) {
        message.success(`${isEdit ? '更新' : '添加'}成功`);
        onCancelHandle(e);
        onOk?.(e);
      }
    } finally {
      setFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      formInstance?.setFieldState('deptId', (field) => {
        if (field.componentProps) {
          field.componentProps.treeData = deptTree;
        }
      });
      formInstance?.setFieldState('postName', (field) => {
        field.disabled = isEdit;
      });
      formInstance?.setFieldState('postCode', (field) => {
        field.disabled = isEdit;
      });
    }
  }, [formMounted, deptTree, isEdit]);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      const { postName, postCode, postSort, status, remark, deptId } = data || {};
      formInstance?.setValues({
        postName,
        postCode,
        postSort,
        status,
        remark,
        deptId,
      });
    }
  }, [formMounted, data]);

  return (
    <Modal
      open={open}
      title={isEdit ? '编辑岗位' : '新增岗位'}
      onOk={onSubmit}
      width={500}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      {...extra}>
      <Form
        className="py-4"
        ref={formRef}
        components={{ Input, Select, Radio, NumberPicker, Switch, InputIcon, TreeSelect }}
        schema={operationSchema}
        formOptions={formOptions}
        hideAction
        layout={{ labelCol: 5, wrapperCol: 19 }}
      />
    </Modal>
  );
}
