import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Input, Select } from '@formily/antd-v5';
import { Button, Modal, message } from 'antd';
import { AiOutlinePlus, AiOutlineDelete } from 'react-icons/ai';

import { deletePost, getPostList } from 'src/api/clients/system';
import { IGetRoleListReq, IPostItem } from 'src/api/types/system';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { PostModal } from './post-modal';

const QueryTablePostsManage = 'SystemPostsManageTableName';

export default function SystemPostsManage() {
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const [currentPost, setCurrentPost] = useState<IPostItem>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const getRolesTableList = useMemoizedFn(async (params: IGetRoleListReq) => {
    const result = await getPostList(params);
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onDeleteRoleByIds = useMemoizedFn(async (ids: number[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定岗位吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deletePost(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      QueryTablePostsManage,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 100,
        renderType: 'Operation',
        renderProps: (col: IPostItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentPost(col);
                  setTrue();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteRoleByIds([col.postId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <div className="m-4 p-6 bg-white rounded-md">
      <QueryTable.Provider name={QueryTablePostsManage} requestFn={getRolesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="postId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
              新增岗位
            </Button>
          }
          extraRightTool={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteRoleByIds(selectedRowKeys as number[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
      <PostModal
        open={open}
        onOk={onRefresh}
        data={currentPost}
        onCancel={() => {
          setFalse();
          setCurrentPost(undefined);
        }}
      />
    </div>
  );
}
