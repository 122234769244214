import { Tag } from 'antd';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import type { IFormSchema } from 'src/components/Form';
import { StatusEnum } from 'src/api/types/common';
import dayjs from 'dayjs';
import styles from './index.module.less';

export const columns: QueryTableColumnProps = [
  {
    title: '岗位名称',
    dataIndex: 'postName',
    key: 'postName',
    width: 200,
  },
  {
    title: '岗位标识',
    dataIndex: 'postCode',
    key: 'postCode',
    width: 200,
  },
  {
    title: '排序',
    dataIndex: 'postSort',
    key: 'postSort',
    width: 150,
  },
  {
    title: '所属部门',
    dataIndex: 'deptName',
    key: 'deptName',
    width: 80,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      return (
        <Tag color={status === StatusEnum.NORMAL ? 'green' : 'red'}>
          {status === StatusEnum.NORMAL ? '正常' : '禁用'}
        </Tag>
      );
    },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: 200,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    render: (createTime: string) => {
      return dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  postName: {
    type: 'string',
    title: '岗位名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入岗位名称',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};

export const operationSchema: IFormSchema = {
  postName: {
    type: 'string',
    title: '岗位名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入岗位名称',
    },
    'x-validator': [{ required: true, message: '请输入岗位名称' }],
  },
  postCode: {
    type: 'string',
    title: '岗位标识',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入岗位标识',
    },
    'x-validator': [{ required: true, message: '请输入岗位标识' }],
  },
  postSort: {
    type: 'number',
    title: '排序',
    'x-component': 'NumberPicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入排序',
    },
    'x-validator': [{ required: true, message: '请输入排序' }],
  },
  deptId: {
    type: 'number',
    title: '所属部门',
    'x-component': 'TreeSelect',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择所属部门',
      popupClassName: styles.post_select_popup,
      treeDefaultExpandAll: true,
    },
    'x-validator': [{ required: true, message: '请选择所属部门' }],
  },
  status: {
    type: 'number',
    title: '状态',
    'x-component': 'Radio.Group',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择状态',
      options: [
        { label: '正常', value: StatusEnum.NORMAL },
        { label: '禁用', value: StatusEnum.STOP },
      ],
    },
    default: StatusEnum.NORMAL,
  },
  remark: {
    type: 'string',
    title: '备注',
    'x-component': 'Input.TextArea',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入备注',
      showCount: true,
      maxLength: 500,
      style: {
        height: 100,
      },
    },
  },
};
